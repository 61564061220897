const green_color = "#00cf5d";
const green_light_color = "#64ffda";
const off_white_color = "#f5f5f5";
const side_bar_white_bg_color = "#E5E8F2";
const sidebar_dark_bg_color = "#000022";
const body_dark_bg_color = "#1e2837";
const card_dark_bg_color = "#0A192F";
const white = "white";
const black = "black";
const crimson_color = "crimson";

module.exports = {
	green_color,
	green_light_color,
	off_white_color,
	crimson_color,
	side_bar_white_bg_color,
	body_dark_bg_color,
	sidebar_dark_bg_color,
	white,
	black,
	card_dark_bg_color,
};
