import React, { createContext, useEffect, useState } from "react";

export const ThemeProvider = createContext();

const ThemeContext = (props) => {
	const [darkTheme, setDarkTheme] = useState(true);
	const [menuOpen, setMenuOpen] = useState(false);

	useEffect(() => {
		const checkTheme = JSON.parse(localStorage.getItem("theme"));
		if (checkTheme !== undefined && checkTheme?.toString().length) {
			setDarkTheme(checkTheme);
		}
	}, []);

	return (
		<ThemeProvider.Provider
			value={{ darkTheme, setDarkTheme, menuOpen, setMenuOpen }}
		>
			{props.children}
		</ThemeProvider.Provider>
	);
};

export default ThemeContext;
