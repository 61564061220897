import { AnimatePresence } from "framer-motion";
import React, { lazy, Suspense, useContext } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import styles from "./app.module.scss";
import {
  black,
  body_dark_bg_color,
  off_white_color,
  white,
} from "./assets/color";
import "./assets/sass/_global.scss";
import { ThemeProvider } from "./ThemeContext";
// import Blog from "./components/Page/Blog";

// const Home = lazy(() => import("./components/Page/Home/Home"));
const About = lazy(() => import("./components/Page/About/About"));
const Contact = lazy(() => import("./components/Page/Contact"));
const Projects = lazy(() => import("./components/Page/Projects"));
const Resume = lazy(() => import("./components/Page/Resume"));
const NotFound = lazy(() => import("./components/Page/NotFound"));

const PageContainer = lazy(() =>
  import("./components/common/PageContainer/PageContainer")
);
const MenuToggleBtn = lazy(() => import("./components/common/MenuBtn/MenuBtn"));
const ThemeToggleBtn = lazy(() =>
  import("./components/common/ThemeToggleBtn/ThemeToggleBtn")
);

export const Loader = () => {
  const { darkTheme } = useContext(ThemeProvider);
  return (
    <div
      style={{
        backgroundColor: darkTheme ? body_dark_bg_color : white,
      }}
      className={styles.loader_wrapper}
    >
      <div className={styles.loader}></div>
    </div>
  );
};

function App() {
  const { darkTheme } = useContext(ThemeProvider);
  const location = useLocation();

  return (
    <Suspense fallback={<Loader />}>
      <AnimatePresence exitBeforeEnter>
        <main
          className="app"
          style={{
            backgroundColor: darkTheme ? body_dark_bg_color : white,
            color: darkTheme ? off_white_color : black,
            height: "100vh",
          }}
        >
          <ThemeToggleBtn />
          <MenuToggleBtn />
          <Switch location={location} key={location.pathname}>
            <Route exact path="/">
              <PageContainer>
                {/* <Home /> */}
                <About />
              </PageContainer>
            </Route>
            {/* <Route exact path="/about">
              <PageContainer>
                <About />
              </PageContainer>
            </Route> */}
            <Route exact path="/projects">
              <PageContainer>
                <Projects />
              </PageContainer>
            </Route>
            {/* <Route exact path="/blog">
              <PageContainer>
                <Blog />
              </PageContainer>
            </Route> */}
            <Route exact path="/contact">
              <PageContainer>
                <Contact />
              </PageContainer>
            </Route>
            <Route exact path="/resume">
              <PageContainer>
                <Resume />
              </PageContainer>
            </Route>
            <Route path="*">
              <PageContainer>
                <NotFound />
              </PageContainer>
            </Route>
          </Switch>
        </main>
      </AnimatePresence>
    </Suspense>
  );
}

export default App;
